import { gsap } from "gsap";

export const createAnimation = (onComplete: () => void) => {
    const instance = getCurrentInstance!();
    if (!instance) throw new Error("No current instance found");

    return {
        action() {
            const panel = instance.vnode.el as HTMLElement;
            const timeline = gsap.timeline();

            timeline
                .set(panel, { display: "block", opacity: 0, y: "200%", left: "50%", x: "-50%" })
                .to(panel, { opacity: 1, y: 0, duration: 1 })
                .to(panel, { y: -200, duration: 1 }, "+=3") // 延迟 3 秒后再继续动画
                .to(panel, { opacity: 0, display: "none", onComplete });

            // 可选：在弹框消失后再将其完全隐藏
            // .set(panel, { display: "none" });
        },
    };
};
