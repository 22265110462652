import { MessageType } from "./index.vue";

const useMessage = () => {
    const message = ref(null) as any;

    const actions = {
        open: (title?: string, type?: MessageType) => {
            if (title) {
                message.value.open(title, type);
            }
        },
        close: () => {
            message.value.close();
        },
    };

    return [message, actions] as const;
};

export { useMessage };
