<template>
    <div class="ws_message_alert m-auto hidden">
        <div data-id="message">
            <!-- Alert -->
            <div v-if="bindState.type === 'default'" class="dasiy-alert px-5 shadow-lg">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="h-6 w-6 flex-shrink-0 stroke-info"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span v-if="bindState.title">{{ bindState.title }}</span>
                    <span v-else>12 unread messages. Tap to see.</span>
                </div>
            </div>
            <!-- Info color -->
            <div v-if="bindState.type === 'info'" class="dasiy-alert dasiy-alert-info px-5 shadow-lg">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="h-6 w-6 flex-shrink-0 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span v-if="bindState.title">{{ bindState.title }}</span>
                    <span v-else>New software update available.</span>
                </div>
            </div>
            <!-- Success color -->
            <div v-if="bindState.type === 'success'" class="dasiy-alert dasiy-alert-success px-5 shadow-lg">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-shrink-0 stroke-current" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span v-if="bindState.title">{{ bindState.title }}</span>
                    <span v-else>Your purchase has been confirmed!</span>
                </div>
            </div>
            <!-- Warning color -->
            <div v-if="bindState.type === 'warning'" class="dasiy-alert dasiy-alert-warning px-5 shadow-lg">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span v-if="bindState.title">{{ bindState.title }}</span>
                    <span v-else>Warning: Invalid email address!</span>
                </div>
            </div>
            <!-- Error color -->
            <div v-if="bindState.type === 'error'" class="dasiy-alert dasiy-alert-error px-5 shadow-lg">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-shrink-0 stroke-current" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span v-if="bindState.title">{{ bindState.title }}</span>
                    <span v-else>Error! Task failed successfully.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { createAnimation } from "./anmation";
import { MESSAAGE_ALERT_KEY } from "./const";
/**
 * Demo:
 * <template>
 *      <GsapMessage ref="messageRef" type="info" title="123"></GsapMessage>
 * </template>
 * import { useMessage } from "@/components/Gsap/Message/useMessage";
 * const [messageRef, message] = useMessage();
 * onMounted(() => {
 *      message.open();
 * });
 */

export type MessageType = "default" | "info" | "success" | "warning" | "error";
const props = withDefaults(defineProps<{ type?: MessageType; title?: string }>(), {
    type: "default",
    title: "",
});

const state = reactive({
    type: props.type,
    title: props.title,
});

const bindState = computed(() => {
    let { type, title } = props;
    return {
        type: state.type || type,
        title: state.title || title,
    };
});

const visible = ref(false);

let anmation = createAnimation(close);

function open(title?: string, type?: MessageType) {
    if (type) {
        state.type = type;
    }
    if (title) {
        state.title = title;
        anmation.action();
    } else return;
}

function close() {
    state.title = props.title;
    state.type = props.type;

    visible.value = false;
}

defineExpose({
    open,
    close,
});
</script>

<style lang="scss" scoped></style>
